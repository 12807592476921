// eslint-disable-next-line import/no-anonymous-default-export
export default {
  gender: [
    {
      value: "male",
    },
    {
      value: "female",
    },
    {
      value: "Does not matter",
    },
  ],
  age: [
    {
      value: "18-25",
    },
    {
      value: "26-35",
    },
    {
      value: "36-45",
    },
    {
      value: "46-55",
    },
    {
      value: "56-65",
    },
    {
      value: "66-75",
    },
    {
      value: "76 or Greater",
    },
    {
      value: "Any age",
    },
  ],
  materialStatus: [
    {
      value: "single",
    },
    {
      value: "married",
    },
    {
      value: "divorced",
    },
    {
      value: "widowed",
    },
    {
      value: "Does not matter",
    },
  ],
  drinker: [
    {
      value: "yes",
    },
    {
      value: "no",
    },
    {
      value: "Does not matter",
    },
  ],
  smoker: [
    {
      value: "No",
    },
    {
      value: "Cigarettes",
    },
    {
      value: "Cigar",
    },
    {
      value: "Vape",
    },
    {
      value: "Other",
    },
    {
      value: "Does not matter",
    },
  ],
  race: [
    {
      value: "White",
    },
    {
      value: "Black",
    },
    {
      value: "Asian",
    },
    {
      value: "Hispanic",
    },
    {
      value: "Mixed",
    },
    // {
    //   value: "American Indian/Alaska Native",
    // },
    {
      value: "Alaska Native",
    },
    {
      value: "Other Islander",
    },
    {
      value: "Does not matter",
    },
  ],
  religion: [
    {
      value: "Christian",
    },
    {
      value: "Catholic",
    },
    {
      value: "Mormon",
    },
    {
      value: "Unaffiliated",
    },
    {
      value: "Atheist",
    },
    {
      value: "Agnostic",
    },
    {
      value: "Jewish",
    },
    {
      value: "Hindu",
    },
    {
      value: "Buddish",
    },
    {
      value: "Does not matter",
    },
  ],
  politicalView: [
    {
      value: "Conservative",
    },
    {
      value: "Libral",
    },
    {
      value: "Independent",
    },
    {
      value: "Non-political",
    },
    {
      value: "Does not matter",
    },
  ],
  favouriteCourse: [
    {
      value: "Bears Best Atlanta",
    },
    {
      value: "Country Land Golf Courses",
    },
    {
      value: "Collins Hill Golf Club",
    },
    {
      value: "Chicopee Woods Golf Courses",
    },
    {
      value: "Chestatee Golf Club",
    },
    {
      value: "Echelon Golf Club",
    },

    {
      value: "Hampton Golf Village",
    },
    {
      value: "Lanier Islands Golf Courses",
    },
    {
      value: "River Pines Golf Courses",
    },
    {
      value: "Reunion Country Club",
    },
    {
      value: "Sugar Hill Golf Club",
    },
    {
      value: "Steel Canyon",
    },
    {
      value: "St Marlo Country Club",
    },
    {
      value: "Windermere Golf Club",
    },

    {
      value: "Woodlands Course at Chateau Elan Golf Club",
    },
    {
      value: "Does not matter",
    },
  ],
  skillLevel: [
    {
      value: "Beginner",
    },
    {
      value: "Below average",
    },
    {
      value: "Average",
    },
    {
      value: "Above Average",
    },
    {
      value: "Advance",
    },
    {
      value: "Does not matter",
    },
  ],

  oftenPlay: [
    {
      value: "daily",
    },
    {
      value: "once a week",
    },
    {
      value: "twice a week",
    },
    {
      value: "three times a month",
    },
    {
      value: "Does not matter",
    },
  ],
  availability: [
    {
      value: "Weekdays",
    },

    {
      value: "Weekends",
    },
    {
      value: "Mornings",
    },
    {
      value: "Mid-day",
    },
    {
      value: "Afternoons",
    },
    {
      value: "Anytime",
    },
  ],
  myAvailability: [
    {
      value: "sunday",
    },
    {
      value: "monday",
    },
    {
      value: "tuesday",
    },
    {
      value: "wednessday",
    },
    {
      value: "thursday",
    },
    {
      value: "friday",
    },
    {
      value: "saturday",
    },
    {
      value: "Anytime",
    },
  ],
  days: [
    {
      value: "sunday",
    },
    {
      value: "monday",
    },
    {
      value: "tuesday",
    },
    {
      value: "wednessday",
    },
    {
      value: "thursday",
    },
    {
      value: "friday",
    },
    {
      value: "saturday",
    },
    {
      value: "Does not matter",
    },
  ],
  time: [
    {
      value: "Morning",
    },
    {
      value: "Mid-day",
    },
    {
      value: "Afternoons",
    },
    {
      value: "Anytime",
    },
  ],
  distance: [
    {
      value: "0-10",
    },
    {
      value: "11-25",
    },
    {
      value: "26-50",
    },
    {
      value: "Any distance",
    },
  ],
  purpose: [
    {
      value: "I just want to play golf",
    },
    {
      value: "I want to meet new friends",
    },
    {
      value: "Dating",
    },

    {
      value: "Networking",
    },
  ],
  industry: [
    {
      value: "Advertising and marketing",
    },
    {
      value: "Aerospace",
    },
    {
      value: "Agriculture",
    },
    {
      value: "Computer technology",
    },
    {
      value: "Construction",
    },
    {
      value: "Education",
    },
    {
      value: "Energy",
    },
    {
      value: "Entertainment",
    },
    {
      value: "Fashion",
    },
    {
      value: "Finance Economic",
    },
    {
      value: "Food and Beverage",
    },
    {
      value: "Government",
    },
    {
      value: "Healthcare",
    },
    {
      value: "Hospitality",
    },
    {
      value: "Manufacturing",
    },
    {
      value: "Media News",
    },
    {
      value: "Mining",
    },
    {
      value: "Pharmaceutical",
    },
    {
      value: "Telecommunication",
    },
    {
      value: "Transportation",
    },
    {
      value: "Does not matter",
    },
  ],
  currentHandicap: [
    {
      name: "0 to 9",
      value: "0-9",
    },
    {
      name: "10 to 15",
      value: "10-15",
    },
    {
      name: "16 to 20",
      value: "16-20",
    },
    {
      name: "21 or Greater",
      value: "21-40",
    },
    {
      name: "No Handicap",
      value: "No Handicap",
    },
  ],
  professional: [
    {
      value: "network",
    },
    {
      value: "looking for a job",
    },
    {
      value: "looking for a change careers",
    },
    {
      value: "Does not matter",
    },
  ],
  company: [
    {
      value: "AT&T",
    },
    {
      value: "Verizon",
    },
    {
      value: "Sprint",
    },
  ],
  companyTitle: [
    {
      value: "Sales",
    },
    {
      value: "Manager",
    },
    {
      value: "Vice President ",
    },
  ],
};
